import React from "react"
import { Box, Heading, Paragraph, Text } from "grommet"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Link from "../components/link"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Box background={{color: "brand"}} fill>
      <Heading>Link Not Found</Heading>
      <Paragraph>Sorry that the link you clicked does not seem to work!</Paragraph>
      <Link
        to={"/"}
        a11yTitle={"3 Leaps return to home link"}
      >
        <Text>Return to home page</Text>
      </Link>
    </Box>
  </Layout>
)

export default NotFoundPage
